import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Login.css';
import axios from 'axios';
import { headers } from './pages/Admin';
import { setSessionToken } from './App';

const loginUser = async (credentials: {username: string|undefined, password: string|undefined}): Promise<string | undefined> => {
    return axios.post("https://app.sometestdomain.xyz/api/user/auth", credentials, { headers: headers })
        .then((response: any) => {
            console.log(response);
            return response.data.token})
        .catch((error: any) => {
            console.log(error)
            return undefined
        })
}

export default function Login({ setToken }: { setToken: any }) {
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = await loginUser({
        username, password
    })
    console.log(token)
    if (token) {
        setSessionToken(token)
        setToken(token)
        // window.location.reload()
    }
    else {
        setError("Неверный логин или пароль!")
    }
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        {error? 
        <div style={{color: "red", fontSize: "14px"}}>
            {error}
        </div>:
         ''}
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
